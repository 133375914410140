<template>
  <div>
    <el-table :data="newCol" :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
              v-loading='loading' :expand-row-keys="expandRow"
              border row-key='id' style="width: 100%">
      <el-table-column label="标题" prop="name">
      </el-table-column>
      <el-table-column label="更改内容">
        <template slot-scope="{row}">
          <template v-if='row.children'>
            <template v-if="returnData(refreshList[row.value],'total')">
              {{ moneyFormat(refreshList[row.value]?.new_total) || '-' }}
            </template>
            <template v-else>
            <span style="text-decoration:line-through">
              {{ moneyFormat(refreshList[row.value]?.old_total) || '-' }}
            </span>
              <span style="color: #409EFF;margin-left: 10px">
              {{ moneyFormat(refreshList[row.value]?.new_total) || '-' }}
            </span>
              <el-button type="text"
                      v-if="refreshList[row.value]?.item_id"   @click="sysOnly(refreshList[row.value],row.value,'total')">
                同步
              </el-button>
            </template>
          </template>


          <template v-else-if='!refreshList[row.value]?.items'>
            <template v-if="row.value==='coop_duration'">
              {{ refreshList[row.value]?.new }}个月
            </template>
            <template v-else>
              <template v-if="returnData(refreshList[row.value],'only')">
                {{ moneyFormat(refreshList[row.value]?.new) || '-' }}
              </template>
              <template v-else>
                <span style="text-decoration:line-through">
                  {{ moneyFormat(refreshList[row.value]?.old) || '-' }}
               </span>
                <span style="color: #409EFF;margin-left: 10px">
                {{ moneyFormat(refreshList[row.value]?.new) || '-' }}
               </span>
                <el-button type="text" @click="sysOnly(refreshList[row.value]?.new,row.value,'only')">同步
                </el-button>
              </template>
            </template>
          </template>
          <template v-else>
              <span v-for='(item, i) in refreshList[row.value].items'
                    v-show="item.name != '' && item.name === row.name" :key='i'>
                           <template v-if="returnData(item,'child')">
                 {{ moneyFormat(item.new) || '-' }}
              </template>
                <template v-else>
                   <span style="text-decoration:line-through"> {{ moneyFormat(item.old) || '-' }}  </span>
                   <span style="color: #409EFF;margin-left: 10px"> {{ moneyFormat(item.new) || '-' }}   </span>
                 <el-button type="text" v-if="refreshList[row.value]?.item_id"  @click="sysOnly(item,row.value,'child')">同步</el-button>
                </template>
              </span>
          </template>
        </template>
      </el-table-column>
    </el-table>
    <el-row style="margin-top: 20px">
      <el-col :span="4" :offset="9">
        <el-button type="primary" @click="refreshBtn()">全部同步</el-button>
      </el-col>
      <el-col :span="4">

        <el-button @click="cancelBtn">关闭</el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'sync',
  data() {
    return {
      isLoading: false,
      refreshList: [],
      canGet: false,
      loading: true,
      expandRow: [],
      newCol: []
    }
  },
  props: {
    colData: {
      type: Array
    },
    sysDate: {
      type: Object
    },
    projectDate: {
      type: Object
    },
    artistId: {
      type: String
    }
  },
  computed: {
    ...mapGetters(['userPermissions'])
  },
  methods: {
    async sysOnly(data, name, type) {
      this.canGet = true
      if (type === 'only') {
        let col = this.sysDate
        let profitData = {
          id: col.id,
          [name]: data
        }
        let msg = await this.$api.modelReviseTotal(profitData)
        if (msg) {
          this.refreshList[name].old = data
        }
      } else if (type === 'total') {
        let items = data.items.map((i) => {
          return { name: i.name, val: i.old }
        })
        let totalData = {
          id: data.item_id,
          content: {
            items,
            total: data.new_total
          }
        }
        let msg = await this.$api.modelRevise(totalData)
        if (msg) {
          this.refreshList[name].old_total = data.new_total
        }
      } else if (type === 'child') {
        let items = this.refreshList[name].items.map((i) => {
          if (i.name === data.name) {
            return { name: i.name, val: Number(data.new) }
          } else {
            return { name: i.name, val: Number(i.old) }
          }
        })
        let totalData = {
          id: this.refreshList[name].item_id,
          content: {
            items,
            total: this.refreshList[name].old_total
          }
        }
        let msg = await this.$api.modelRevise(totalData)
        if (msg) {
          let index = this.refreshList[name].items.map((i, j) => {
            return i.name === data.name ? String(j) : false
          }).filter(Boolean)
          this.refreshList[name].items[index].old = data.new
        }
      }
    },
    // pushExce(data){
    //   if (Number(data?.old) === Number(data?.new)) {
    //   }
    // },
    returnData(data, type) {
      if (type === 'total') {
        if (Number(data?.new_total) === Number(data?.old_total)) {
          return true
        } else {
          return false
        }
      } else if (type === 'only') {
        data?.new === '-' ? data.new = 0 : ''
        if (Number(data?.old) === Number(data?.new)) {
          return true
        } else {
          return false
        }
      } else if (type === 'child') {
        if (Number(data?.old) === Number(data?.new)) {
          return true
        } else {
          return false
        }
      }
    },
    refreshBtn() {
      this.$confirm(
          `确定同步【${this.sysDate.month}月份】的数据吗？`,
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(async () => {
        this.isLoading = true
        let col = this.sysDate

        let RefreshData = {
          artist_id: this.artistId,
          date: `${this.projectDate.year}-${String(col.month < 10 ? '0' + col.month : col.month)}-01`
        }
        let data = await this.$api.modelRefresh(RefreshData)
        if (data) {
          this.canGet = true
          this.$emit('cancel', true)
          this.$message({
            message: '同步成功', type: 'success'
          })
        }
      })
    },
    cancelBtn() {
      this.$emit('cancel', true)
    },
    async refresh() {
      let month = this.sysDate.month
      let col = this.sysDate
      // this.dialogSync = true
      let date = `${this.projectDate.year}-${String(month < 10 ? '0' + month : month)}-01`
      let data = {
        artist_id: this.artistId,
        date: date
      }

      let refreshList = await this.$api.modelSyncData(data)
      let newCol = {}
      let newArray = []
      for (let key in col) {
        if (refreshList[key] || refreshList[key] === 0) {
          if (['string', 'number'].indexOf(typeof refreshList[key]) > -1) {
            newCol[key] = { old: col[key] || '0', new: refreshList[key] || '0' }
          } else if (['object'].indexOf(typeof refreshList[key]) > -1) {
            let items = []
            col[key].items.forEach((i) => {
              let v = 0
              refreshList[key].items.forEach(j => {
                j.name === i.name ? v = j.val : ''
              })
              items.push({
                name: i.name,
                old: Math.floor(i.val * 100) / 100|| '0',
                new: Math.floor(v * 100)/ 100 || '0'
              })
              if (i.val != v) {
                newArray.push(key)
              }
            })
            newCol[key] = {
              item_id: col[key].item_id,
              new_total: refreshList[key].total || '0',
              old_total: Math.floor(col[key].total * 100)/ 100 || '0',
              items: items
            }

          }
        }
        if (key === 'coop_duration') {
          newCol[key] = {
            old: col[key]
            , new: col[key]
          }
        }
      }
      newArray = Array.from(new Set(newArray))

      let newData = this.colData.map((i) => {
        return newArray.includes(i.value) ? String(i.id) : false
      }).filter(Boolean)
      this.expandRow = newData
      this.refreshList = newCol
      this.loading = false
    },
    ///其他数据计算
    moneyFormat(value) {
      return this.$utils.numberFormat(value, 2, '.', ',', 'round')
    },
    getCol() {
      this.newCol = this.colData
      // this.newCol = this.newCol.filter((i) => {
      //   return !(i.id === 20)
      // })
    }
  },
  mounted() {
    this.refresh()
    this.getCol()
  }
}
</script>

<style lang="scss" scoped>

.row > * {
  text-align: center;
}

::v-deep .el-table th {
  overflow: initial;
}

::v-deep.el-table th > .cell {
  font-family: PingFangSC-Regular, sans-serif;
  font-size: 14px;
  color: #2e3444;
  text-align: center;

}


::v-deep.el-table thead tr:last-of-type th:first-of-type:before {
  text-align: center;
  position: absolute;
  width: 1px;
  background-color: #ebeef5;
  display: block;

}

::v-deep.el-table thead tr:last-of-type th:first-of-type:before {
  height: 183px; //根据情况调整
  bottom: 0;
  right: 0; //根据情况调整
  transform: rotate(-72deg); //根据情况调整
  transform-origin: bottom;
}

::v-deep.el-table tbody tr td:first-of-type .cell {
  font-family: PingFangSC-Regular, sans-serif;
  font-size: 14px;
  color: #2e3444;
  font-weight: 600;
  text-align: center;

}

::v-deep .el-table--mini .el-table__cell {
  padding: 8px 0;
}
</style>
