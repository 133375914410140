<template>
  <div id="mainPie" ref="main_pie" class="mainPie" style="height:300px"></div>

</template>

<script>
import * as echarts from 'echarts'

export default {
  name: 'PieChart',
  props: {
    dataList: {
      type: Array
    }
  },
  methods: {
    async mainPer() {
      let myChart = echarts.init(this.$refs.main_pie)
      let chartOption = {
        legend: {
          left: '70%',
          top: '60%'
        },
        tooltip: {
          trigger: 'item'
        },
        title: {
          text: '收入占比图',
          left: 'center'
        },
        toolbox: {
          show: false
        },
        colorList: ['#ed7d31','#EA7267', '#F0D84B', '#7AE72F'],


        series: []
      }
      if (this.dataList && this.dataList.length > 0) {
        let newArr = {
          type: 'pie',
          radius: [30, 100],
          center: ['50%', '45%'],
          roseType: 'area',
          itemStyle: {
            borderRadius: 8
          },
          data: []
        }


        let allDate = {
          ad_income: 0,
          live_commission: 0,
          short_video: 0
        }
        this.dataList.forEach((item) => {
          allDate.ad_income = allDate.ad_income + Number(item['ad_income'].total)
          allDate.live_commission = allDate.live_commission + Number(item['live_commission'].total)
          allDate.short_video = allDate.short_video + Number(item['short_video'].total)
        })
        newArr.data = [
          { value: allDate.ad_income, name: '广告收入' },
          { value: allDate.live_commission, name: '直播佣金收入' },
          { value: allDate.short_video, name: '短视频收入' }
        ]
        chartOption.series = [newArr]
        myChart.setOption(chartOption)

      }
    }
  },
  mounted() {
    this.mainPer()
  }
}
</script>

<style scoped>

</style>
