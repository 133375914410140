<template>
  <div>
      <div style="float:right;margin-right: 10px">
        <br>
        简约版：
        <el-switch
            @change="changeV"
            v-model="changeSimple"
            active-color="#13ce66"
            inactive-color="#ff4949">
        </el-switch>
      </div>
      <div class="main-page-content">
        <div class="title">
          <h1>{{ projectDate.year }}年{{ formData.nickname }}红人平台&财务数据分析</h1>
        </div>
        <el-row v-if="changeSimple">
          <div class="text">
            <span> <b>所属小组:</b> {{ headData.group_name || '暂无' }} </span>
            <span>
              <b>红人分类:</b>
              <span v-show="!inputShow" title="双击可修改" @dblclick="dbBtn('category')">{{
                  headData.category || '暂无'
                }} </span>
              <span v-if="inputShow">
                <el-input v-model="headData.category" placeholder="请输入类别" style="width: 120px" autofocus
                          @blur="updateBtn('category')"></el-input>
              </span>
            </span>
            <span>
              <b>变现时间：</b>
              <span v-show="!timeShow" title="双击可修改" @dblclick="dbBtn('time')">{{
                  headData.realization_time || '暂无'
                }} </span>
              <span v-show="timeShow">
                <el-date-picker focus v-model="headData.realization_time" type="date" @blur="updateBtn('time')"
                                value-format="yyyy-MM-dd" placeholder="选择日期时间"> </el-date-picker>
              </span>
            </span>
            <span> <b>签约时间：</b> {{ headData.sign_at || '暂无' }} </span>
            <span><b>签约博主分成比例 </b>：{{ headData.kol_divide_rate || '-' }}%</span>
          </div>
        </el-row>

        <el-row :gutter="20" style="margin-left: 0" v-else>
          <el-col :sm="3" :md="3" :lg="5" :xl="5">
            <div class="infoBlock">
              <h3 style="font-size: 22px">{{ headData.group_name || '暂无' }}</h3>
              <span>所属小组</span>
            </div>
            <div class="infoBlock" v-if="userPermissions.indexOf('kol-analysis-artist-save') !== -1">
              <h3 v-show="!inputShow" title="双击可修改" @dblclick="dbBtn('category')">{{
                  headData.category || '暂无'
                }}</h3>
              <h4 v-if="inputShow">
                <el-input v-model="headData.category" placeholder="请输入类别" style="width: 120px"
                          @blur="updateBtn('category')"></el-input>
              </h4>
              <span>红人分类</span>
            </div>
            <div class="infoBlock" v-else>
              <h3>{{ headData.category || '暂无' }}</h3>
              <span>红人分类</span>
            </div>
          </el-col>
          <el-col :sm="3" :md="3" :lg="5" :xl="5">
            <div class="infoBlock" v-if="userPermissions.indexOf('kol-analysis-artist-save') !== -1">
              <h3 v-show="!timeShow" title="双击可修改" @dblclick="dbBtn('time')">{{
                  headData.realization_time || '暂无'
                }}</h3>
              <span>变现时间</span>
              <h4 v-show="timeShow">
                <el-date-picker v-model="headData.realization_time" type="date" @blur="updateBtn('time')"
                                value-format="yyyy-MM-dd" placeholder="选择日期时间"></el-date-picker>
              </h4>
            </div>
            <div class="infoBlock" v-else>
              <h3>{{ headData.realization_time || '暂无' }}</h3>
              <span>变现时间</span>
            </div>
            <div class="infoBlock">
              <h3>{{ headData.sign_at || '暂无' }}</h3>
              <span>签约时间</span>
            </div>
          </el-col>
          <el-col :sm="3" :md="3" :lg="5" :xl="5">
            <div class="infoBlock">
              <h3>{{ headData.kol_divide_rate ? headData.kol_divide_rate + '%' : '-' }}</h3>
              <span>博主分成比例</span>
            </div>
            <div class="infoBlock">
              <h3 :title="getProfit">{{ getProfit.length > 17 ? getProfit.slice(0, 16) + '...' : getProfit }}</h3>
              <span>预计利润</span>
            </div>
          </el-col>
          <el-col :sm="2" :md="2" :lg="3" :xl="3">
            <div class="kolUser">
              <div class="avatar">
                <el-image style="width: 100px; height: 100px" :src="avatar"></el-image>
              </div>
              <h1 style="font-size: 30px;text-align: center">{{
                  formData.nickname
                }} </h1>
            </div>
          </el-col>
          <el-col :sm="7" :md="7" :lg="6" :xl="6">
            <PieChart v-if="!tableLoading" :dataList="dataList"></PieChart>
          </el-col>
        </el-row>
        <el-card class="box-card">
          <el-skeleton :rows="6" animated v-show="tableLoading"/>
          <data-table v-if="!tableLoading"
                      @callbackData="callbackData"
                      :formData="formData"></data-table>
        </el-card>

        <!--                                <el-row :gutter="20" style="margin-left: 0">-->
        <!--                                  <el-col :span="8">-->
        <!--                                    <div class="lineRow">-->
        <!--                                      <div id="mainLine" ref="main_line" class="mainLine" style="width: 100%;height:400px"></div>-->
        <!--                                    </div>-->
        <!--                                  </el-col>-->
        <!--                                  <el-col :span="8">-->
        <!--                                    <div class="lineRow">-->
        <!--                                      <LineChart :title="'收入合计'"></LineChart>-->
        <!--                                    </div>-->
        <!--                                  </el-col>-->
        <!--                                  <el-col :span="8">-->
        <!--                                    <div class="lineRow">-->
        <!--                                      <LineChart :title="'收入合计'"></LineChart>-->
        <!--                                    </div>-->
        <!--                                  </el-col>-->
        <!--                                </el-row>-->
      </div>
      <br/>
  </div>
</template>
<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import DataTable from '@/pages/kolModel/components/DataTable.vue'
import PieChart from '@/pages/kolModel/components/PieChart.vue'
// import * as echarts from 'echarts'
import avatar from '@/assets/avatar.png'
import { mapGetters } from 'vuex'
import Loading from '@/components/loading.vue'

export default {
  name: 'kolModelDetail',
  components: {
    PageHeaderLayout,
    DataTable,
    PieChart,
    Loading
  },
  watch: {
    formData: {
      immediate: true,
      handler() {
        this.headData = this.formData
      }
    }
  },
  data() {
    return {
      avatar: avatar,
      tableLoading: true,
      currentTabs: [],
      formData: {},
      labelPosition: 'left',
      dataList: [],
      artistId: '',
      projectDate: { year: '', month: '' },
      headData: {},
      timeShow: false,
      inputShow: false,
      changeSimple: false
    }
  },
  computed: {
    ...mapGetters(['userPermissions']),
    getFansData() {
      let data = this.dataList[this.dataList.length - 1]?.fans_total?.total || 0
      return data
    },

    getProfit() {
      let retunData = 0
      this.dataList.forEach((item) => {
        if (item.profit) {
          retunData += Number(item.profit)
        }
      })
      return this.$utils.numberFormat(retunData, 2, '.', ',', 'round')
    }
  },
  methods: {

    changeV() {
      localStorage.setItem('changeSimple', this.changeSimple)
    },
    async updateBtn(type) {
      let update = {}
      if (type === 'time') {
        this.timeShow = false
        update = {
          id: this.artistId,
          realization_time: this.headData.realization_time
        }
      } else if (type === 'category') {
        this.inputShow = false

        update = {
          id: this.artistId,
          category: this.headData.category
        }
      }
      let data = await this.$api.modelUpdateArtist(update)

      if (data) {
        this.$message({
          message: '修改成功',
          type: 'success'
        })
        await this.getDataList()
      } else {
        this.$message({
          message: '失败'
        })
        this.tableLoading = false
      }
    },
    dbBtn(type) {
      if (type === 'time') {
        this.timeShow = true
      } else if (type === 'category') {
        this.inputShow = true
      }
    },
    callbackData(event) {
      if (event) {
        this.tableLoading = true
        setTimeout(() => {
          this.tableLoading = false
        }, 800)
      }
    },
    async getDataList() {
      let date = `${this.projectDate.year}-${this.projectDate.month}-01`
      let list = await this.$api.modelGetModelDetail({ artist_id: this.artistId, date: date })
      this.dataList = list
      let data = await this.$api.modelGetModelTitle({ artist_id: this.artistId })
      this.formData = data
      this.tableLoading = false
    },
    // async getChartData() {
    //   let myChart = echarts.init(this.$refs.main_line)
    //   let chartOption = {
    //     title: {
    //       show: true,
    //       text: 'text'
    //     },
    //     tooltip: {
    //       trigger: 'axis',
    //       axisPointer: {
    //         type: 'shadow'
    //       }
    //     },
    //     legend: {
    //       show: false
    //     },
    //     xAxis: {
    //       type: 'value',
    //       boundaryGap: [0, 0.01]
    //     },
    //     color: ['#CEAD52'],
    //     yAxis: {
    //       type: 'category',
    //       data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
    //     },
    //     series: [
    //       {
    //         type: 'bar',
    //         data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 1, 12]
    //       }
    //     ]
    //   }
    //
    //   myChart.setOption(chartOption)
    // }
  },
  mounted() {
    this.artistId = this.$route.params.id
    this.projectDate = { year: this.$route.query.year, month: this.$route.query.month }
    this.changeSimple = !!(localStorage.getItem('changeSimple') && localStorage.getItem('changeSimple') != 'false')
    this.getDataList()

  }
}
</script>

<style lang="scss" scoped>
/* CDN 服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */
@font-face {
  font-family: 'xt';
  font-weight: 400;
  src: url('//at.alicdn.com/wf/webfont/Z6Z6Ve9CViat/St9qE1I7fYHN.woff2') format('woff2'), url('//at.alicdn.com/wf/webfont/Z6Z6Ve9CViat/ZrrFFWybd5kb.woff') format('woff');
  font-display: swap;
}

.text > span {
  display: inline-block;
  margin: 10px;
  font-size: 16px;
  font-family: xt;

  > b {
    //font-weight: bold;
    margin-right: 4px;
  }
}

.main-page-content {

  .title {
    font-size: 20px;
    text-align: center;
    //height: 100px;
    border-radius: 10px;
    margin-bottom: 20px;
    color: #164028;

    > h1 {
      font-family: xt;
      //line-height: 100px;
    }
  }

  .lineRow {
    border: 2px solid #660373;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.6);
    margin-bottom: 20px;
  }

  .mainPie {
    //border: 2px solid #8C5D23;
    //background-color: rgba(255, 255, 255, 0.6);
  }

  .infoBlock {
    background-color: rgba(255, 255, 255, 0.6);
    border: 2px dashed #660373;
    border-radius: 10px;
    height: 100px;
    text-align: center;
    margin-bottom: 30px;
    color: #660373;
    font-size: 16px;

    > h3 {
      font-family: xt;
      letter-spacing: 2px;
      color: #f24b88;
      font-size: 30px;
      margin-bottom: 10px;
    }

    > span {
      font-size: 14px;
    }
  }

  @media (max-width: 1450px) {
    .infoBlock {
      > h3 {
        font-size: 26px;
      }
    }
  }

  @media (max-width: 1250px) {
    .infoBlock {
      > h3 {
        font-size: 22px;
      }
    }
  }

  @media (max-width: 900px) {
    .infoBlock {
      > h3 {
        font-size: 16px;
      }
    }
  }

  .kolUser {
    > h1 {
      //border: 1px solid red;
      line-height: 100px;
      margin-top: 30px;
      color: #164028;
    }

    .avatar {
      width: 100px;
      height: 100px;
      margin: 0 auto;

      > * {
        border-radius: 10px;
      }

      border: 2px double #660373;
      border-radius: 10px;
      background-color: rgba(255, 255, 255, 0.6);
    }
  }
}
</style>
