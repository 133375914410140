<template>
  <div id="menu-comm">
    <div  v-show="menuData.visible" :style="{left:menuData.left+'px',top:menuData.top+'px'}" class="menu">
      <div class="contextmenu__item" @click="checkEvent('refresh')" v-if="userPermissions.indexOf('kol-analysis-item-sync') !== -1">
        <i class="el-icon-refresh" style='color: #409EFF'></i>
        同步当月数据
      </div>
<!--      <div class="contextmenu__item"-->
<!--           @click="checkEvent('edit')">-->
<!--        <i style='color: #ff3176' class="el-icon-edit"></i>-->
<!--        编辑月数据-->
<!--      </div>-->
      <div class="contextmenu__item" v-if="userPermissions.indexOf('kol-analysis-item-create') !== -1"
           v-show="menuData.type==='total'" @click="checkEvent('add')">
        <i class="el-icon-plus" style='color: #ff3176'></i> 新增平台
      </div>
      <div class="contextmenu__item" v-if="userPermissions.indexOf('kol-analysis-item-create') !== -1"
           v-show="menuData.type==='child'" @click="checkEvent('del')">
        <i class="el-icon-delete" style='color: red'></i> 删除
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Contextmenu',
  props: {
    menuData: {
      type: Object,
      default() {
        return {
          visible: false,
          top: 0,
          left: 0,
          type:''
        }
      }
    }
  },
  watch: {
    menuData: {
      deep: true,
      handler() {
        // this.initList()
      }
    }
  },
  computed: {
    ...mapGetters(['userPermissions']),
  },
    data() {
    return {
      menuVisible: false
    }
  },
  methods: {
    closeMenu() {
      this.menuData.visible = false
    },
    checkEvent(val) {
      this.$emit('change', val)
      this.menuData.visible = false
    }
  }
}
</script>

<style scoped>

.contextmenu__item {
  display: block;
  line-height: 34px;
  text-align: center;
}

.contextmenu__item:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.menu {
  position: absolute;
  background-color: #fff;
  width: 120px;
  /*height: 106px;*/
  font-size: 14px;
  color: #444040;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  white-space: nowrap;
  z-index: 1000;
}

.contextmenu__item:hover {
  cursor: pointer;
  background: #ff3176;
  border-color: #ff3176;
  color: #fff;
}
</style>
