<template>
  <div>
    <h3 title="点击展开详情" style="cursor: pointer;font-size: 14px" @click="clickbtn" class="title">
      操作日志 <i
        :class="this.logShow?'el-icon-caret-top':'el-icon-caret-bottom'"></i></h3>
    <div class="default-table" v-if="logShow">
      <el-table :data="modelLog" border>
        <el-table-column align="center" label="id" prop="id" width="60"></el-table-column>
        <el-table-column align="center" label="操作用户" width="80"
                         show-overflow-tooltip prop="nickname"></el-table-column>
        <el-table-column align="left" label="操作详情" min-width="200"
                         header-align="center" show-overflow-tooltip prop="content"></el-table-column>
        <el-table-column align="center" label="操作时间"  width="200"
                         header-align="center" show-overflow-tooltip prop="created_at"></el-table-column>
      </el-table>
      <el-row>
        <el-col :span='24' style='text-align: right'>
          <Pagination :limit.sync='pagingData.page_size'
                      :page.sync='pagingData.current_page'
                      :total='pagingData.total'
                      @pagination='getList'/>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LogList',
  data() {
    return {
      modelLog: [],
      pagingData: {
        page_size: 20,
        current_page: 1,
        total: 0
      },
      logShow: false,
      selectInfo: {}
    }
  },
  props: {
    logIds: {
      type: Array
    }
  },
  methods: {
    handleData() {
      this.pagingData.current_page = 1
      this.getList()
    },
    async getList() {
      this.selectInfo = {
        info_ids: this.logIds
      }
      Object.assign(this.selectInfo, {
        page_size: this.pagingData.page_size || 10,
        current_page: this.pagingData.current_page || 1
      })
      const { list, page_info } = await this.$api.modelUserLog(this.selectInfo)
      this.modelLog = list
      this.pagingData = page_info
      this.isLoading = false

    },
    clickbtn() {
      this.logShow = !this.logShow
      if (this.logShow) {
        this.handleData()
      }
    }
  }
  // modelUserLog
}
</script>

<style scoped>

</style>
