<template>
  <div class="section">
    <div class="loader-item" style="--c: #e94545; --i: 0"></div>
    <div class="loader-item" style="--c: #eb8f34; --i: 1"></div>
    <div class="loader-item" style="--c: #eecf69; --i: 2"></div>
    <div class="loader-item" style="--c: #215221; --i: 3"></div>
    <div class="loader-item" style="--c: #87bb80; --i: 4"></div>
    <div class="loader-item" style="--c: #87ceeb; --i: 5"></div>
    <div class="loader-item" style="--c: #c393eb; --i: 6"></div>
  </div>
</template>

<script>
export default {
  name: 'loading'
}
</script>

<style scoped>

.section {
  width: 200px;
  height: 200px;
}

.loader-item {
  --width: calc(200px - 15px * 2 * var(--i));
  width: var(--width);
  height: calc(var(--width) / 2);
  border:  5px solid var(--c);
  border-radius: 50% 50% 0 0/100% 100% 0 0;
  border-bottom: none;
  position: absolute;
  left: calc(50% - var(--width) / 2);
  top: calc(15px * var(--i));
  transform-origin: 50% 100%;
  animation: rotate 2s cubic-bezier(0.11, 0.85, 0.22, 1.3) infinite;
  animation-delay: calc(-60ms * var(--i));
  transition: all 0.5s;
}

/*section:hover .loader-item {*/
/*  filter: brightness(1.5);*/
/*  animation-play-state: paused;*/
/*}*/

/*@keyframes rotate {*/
/*  0%,*/
/*  25% {*/
/*    transform: rotate(0deg);*/
/*  }*/
/*  100% {*/
/*    transform: rotate(360deg);*/
/*  }*/
/*}*/
</style>
